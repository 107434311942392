import { TimeZonesStore } from '@go-widgets/well-logs-widget/dist/stores/timeZonesStore';
import { format, parseISO } from 'date-fns';
import { action, computed, flow, makeObservable, observable, set } from 'mobx';
import { throttle } from 'throttle-debounce';

import { OperationAbortedError } from 'src/app/errors';
import { requireService } from 'src/packages/di';

import type { BroadcastObject, Wellbore, WellDto } from './api/Wellbore.api';
import type { AppParams, WidgetEvents, WidgetStateParams } from './types';

import { WellboreApi } from './api/Wellbore.api';
import { ImageBroadcastChannel } from './ImageBroadcastChannel';
import { ImageBroadcasts } from './ImageBroadcasts';
import { Wellbores } from './Wellbores';
import { WidgetStatePublisher } from './WidgetStatePublisher';

export class ImageBroadcastingWidgetState {
  wellboreApi: WellboreApi;

  wellbores: Wellbores;

  imageBroadcasts: ImageBroadcasts;

  timeZonesStore: TimeZonesStore;

  statePublisher: WidgetStatePublisher;

  @observable appParams: AppParams;

  @observable wellId: number;

  @observable wellData: WellDto | null = null;

  @observable status: 'initial' | 'loading' | 'ready' | 'failed' = 'initial';

  @observable isPlaying: boolean;

  @observable selectedBroadcast: ImageBroadcastChannel | null = null;

  @observable selectedWellbore: Wellbore | null = null;

  abortController: AbortController | null = null;

  throttledSetWell: (wellId: number) => void;

  constructor(
    appParams: AppParams,
    initState: Partial<WidgetStateParams>,
    private eventHandlers: WidgetEvents,
    private notifications = requireService('notifications')
  ) {
    this.wellId = appParams.wellId;
    this.appParams = observable.object(appParams);

    this.statePublisher = new WidgetStatePublisher(initState, this.eventHandlers?.onInternalStateChanged);
    this.wellboreApi = new WellboreApi();
    this.wellbores = new Wellbores(this.wellboreApi);
    this.imageBroadcasts = new ImageBroadcasts(this.wellboreApi);
    this.timeZonesStore = new TimeZonesStore(this.statePublisher.params.timeZone ?? 'user', (value) => {
      this.statePublisher.setParams({ timeZone: value });
    });
    this.isPlaying = initState.isPlaying ?? true;

    makeObservable(this);

    this.throttledSetWell = throttle(800, this.setWell, {});

    this.init();
  }

  @computed
  get currentImageUrl() {
    if (!this.selectedBroadcast || !this.selectedBroadcast.info || !this.selectedBroadcast.image) {
      return null;
    }

    const formattedDate = format(parseISO(this.selectedBroadcast.info.updateTime), 'dd.MM.yyyy HH-mm-ss');

    return {
      url: this.selectedBroadcast.image,
      alt: [this.selectedBroadcast?.info.name, this.selectedWellbore?.name, this.wellData?.name, formattedDate].join(
        '_'
      ),
    };
  }

  @flow.bound
  async *init() {
    this.status = 'loading';

    try {
      await this.setWell(this.wellId);

      yield;
      if (this.eventHandlers.onInitializedSuccess) {
        this.eventHandlers.onInitializedSuccess();
        this.status = 'ready';
      }
    } catch (e) {
      yield;
      if (this.eventHandlers.onInitializationFailed) {
        if (e instanceof Error) {
          this.eventHandlers.onInitializationFailed(e.message, e);
        } else {
          this.eventHandlers.onInitializationFailed('Unexpected error');
        }
      }
      this.status = 'failed';
    }
  }

  @flow.bound
  async *setWell(wellId: number) {
    if (this.abortController) {
      this.abortController.abort();
    }
    this.abortController = new AbortController();

    this.updateWellData(wellId, this.abortController);

    this.selectedBroadcast = null;

    const wellbores = await this.wellbores.fetchWellbores(wellId, this.abortController);
    yield;

    const selected =
      wellbores.find((wellbore) => wellbore.id === this.statePublisher.params.wellboreId) ?? wellbores[0];
    if (selected) {
      this.setWellbore(selected);
    } else {
      this.imageBroadcasts.clear();
      if (this.eventHandlers.onTitleChanged) {
        this.eventHandlers.onTitleChanged('');
      }
    }
  }

  @flow.bound
  async *updateWellData(wellId: number, abortController: AbortController) {
    try {
      const well = await this.wellboreApi.getWell(wellId, abortController);
      yield;
      this.wellData = well;
      this.timeZonesStore.setWellTimeZoneByString(well.timeZone);
    } catch (e) {
      yield;
      if (!(e instanceof OperationAbortedError)) {
        this.notifications.showErrorMessageT('imageBroadcast:error.well');
      }
    }
  }

  @flow.bound
  async *setWellbore(wellbore: Wellbore) {
    if (!this.abortController) {
      return;
    }
    this.selectedWellbore = wellbore;

    if (this.eventHandlers.onWellboreChanged) {
      this.eventHandlers.onWellboreChanged(this.selectedWellbore.name);
    }

    const list = await this.imageBroadcasts.fetchBroadcasts(wellbore.id, this.abortController);
    yield;
    if (list[0]) {
      let prevBroadcastName = this.selectedBroadcast?.info?.name ?? null;
      if (prevBroadcastName) {
        const broadcast = list.find((b) => b.text === prevBroadcastName) ?? list[0];
        this.setBroadcast(broadcast);
      } else {
        const broadcast = list.find((b) => Number(b.id) === this.statePublisher.params.broadcastId) ?? list[0];
        this.setBroadcast(broadcast ?? list[0]);
      }
    } else {
      if (this.eventHandlers.onTitleChanged) {
        this.eventHandlers.onTitleChanged('');
      }
    }
  }

  @action.bound
  setBroadcast(broadcast: BroadcastObject) {
    if (this.selectedBroadcast) {
      this.selectedBroadcast.unsubscribe();
    }
    this.selectedBroadcast = new ImageBroadcastChannel(Number(broadcast.id), this.wellboreApi);
    this.selectedBroadcast.update();
    if (this.isPlaying) {
      this.selectedBroadcast.subscribe();
    }
    if (this.selectedWellbore) {
      this.statePublisher.setParams({
        broadcastId: Number(broadcast.id),
        wellboreId: this.selectedWellbore.id,
      });
    }
    if (this.eventHandlers.onTitleChanged) {
      this.eventHandlers.onTitleChanged(broadcast.text);
    }
  }

  @action.bound
  setAppParams(params: Partial<AppParams>) {
    if (params.wellId && params.wellId !== this.appParams.wellId) {
      this.wellId = params.wellId;
      this.throttledSetWell(params.wellId);
    }
    set(this.appParams, params);
  }

  @action.bound
  setPlayingState(value: boolean) {
    this.isPlaying = value;
    this.statePublisher.setParams({
      isPlaying: value,
    });
    if (this.selectedBroadcast) {
      if (value) {
        this.selectedBroadcast.update();
        this.selectedBroadcast.subscribe();
      } else {
        this.selectedBroadcast.unsubscribe();
      }
    }
  }

  @action.bound
  refetchBroadcasts() {
    if (this.selectedWellbore && this.abortController) {
      this.imageBroadcasts.fetchBroadcasts(this.selectedWellbore.id, this.abortController);
    }
  }

  @action.bound
  destroy() {
    if (this.selectedBroadcast) {
      this.selectedBroadcast.unsubscribe();
    }
  }
}
