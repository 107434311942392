import { DirectionalDrillingMonitor, type DirectionalDrillingStateParams } from '@go-widgets/well-logs-widget';
import { FullArrowLight as FullIcon, ReduceLight as MinimizeIcon } from '@profgeosoft-ui/icons';
import { Tooltip, Button } from '@profgeosoft-ui/react';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useService } from 'src/packages/di';

import type { DirectionalDrillingWidgetEntity } from './DirectionalDrillingWidget.entity';
import type { TWidgetRendererOptions } from '../workspace/services/WidgetRendererService';
import type { TCreateWidgetOptions } from 'src/entities/tab/TabEntity';

import { NoWellChosen } from '../no-well-chosen';
import { WidgetHeader } from '../widget-header/WidgetHeader';

import { DirectionalDrillingWidgetStore } from './DirectionalDrillingWidget.store';

import styles from './DirectionalDrillingWidget.module.scss';

type WrapperProps = {
  entity: DirectionalDrillingWidgetEntity;
  options: TWidgetRendererOptions;
  onCreateDirectionalDrillingWidget(
    wellId: number | null,
    stateParams: Partial<DirectionalDrillingStateParams> | null,
    options?: TCreateWidgetOptions
  ): void;
};

type Props = {
  store: DirectionalDrillingWidgetStore;
  entity: DirectionalDrillingWidgetEntity;
  options: TWidgetRendererOptions;
};

export const DirectionalDrillingWidgetWrapper = observer(function DirectionalDrillingWidgetWrapper({
  entity,
  options,
  onCreateDirectionalDrillingWidget,
}: WrapperProps) {
  const [store, setStore] = useState<DirectionalDrillingWidgetStore | null>(null);
  const storeService = useService('widgetStoreService');

  useEffect(() => {
    if (store) return;

    const _store = storeService.getStore<DirectionalDrillingWidgetEntity, DirectionalDrillingWidgetStore>(entity);

    if (_store) {
      setStore(_store);
      if (_store.groupSelectStore == null) {
        _store.setGroupSelectStore(options.groupSelectStore);
      }
    } else {
      const newStore = new DirectionalDrillingWidgetStore(
        entity,
        options.groupSelectStore,
        onCreateDirectionalDrillingWidget
      );

      storeService.setStore<DirectionalDrillingWidgetEntity, DirectionalDrillingWidgetStore>(entity, newStore);

      setStore(newStore);
    }
  }, [setStore, storeService, entity, options.groupSelectStore, store, onCreateDirectionalDrillingWidget]);

  if (!store) return null;

  return <DirectionalDrillingWidget store={store} entity={entity} options={options} />;
});

export const DirectionalDrillingWidget = observer(function DirectionalDrillingWidget({
  entity,
  options,
  store,
}: Props) {
  const { setGroupForWellLogs, isReady, effect, exitFullScreen } = store;

  const { t } = useTranslation();

  const { wellId, isFullscreen, state, setFullscreen, wellboreName } = entity;

  useEffect(effect, [effect]);

  const directories = useService('directories');
  const wells = directories.getObject('GOdashboard_Well');
  const wellName = wells?.find((item) => item.id === wellId)?.data.name ?? wellId;

  return (
    <div className={styles.widgetWrapper}>
      <WidgetHeader
        entity={entity}
        onWidgetChangeGroup={setGroupForWellLogs}
        groupSelectStore={options.groupSelectStore}
        widgetName={entity.title}
        wellName={wellName}
        wellboreName={wellboreName}
        onWidgetDelete={() => {
          options.onWidgetDelete(entity);
        }}
        showCloseButton={!isFullscreen}
        draggable={!isFullscreen}
        widgetNameClassName={styles.directionalDrillingName}
        widgetTitleClassName={styles.directionalDrillingTitle}
        renderAdditionalButtons={() => {
          if (!isReady) return null;

          return (
            <>
              {!isFullscreen && (
                <Tooltip title={t('common:Widget.fullscreen')} delay={400}>
                  <Button
                    onClick={() => setFullscreen(true)}
                    variant="flat"
                    icon={<FullIcon className={styles.icon} />}
                    className={styles.button}
                  />
                </Tooltip>
              )}
              {isFullscreen && (
                <Tooltip title={t('common:Widget.collapse')} delay={400}>
                  <Button
                    onClick={() => exitFullScreen()}
                    variant="flat"
                    icon={<MinimizeIcon className={styles.icon} />}
                    className={styles.button}
                  />
                </Tooltip>
              )}
            </>
          );
        }}
      />
      {state ? (
        <div className={styles.wrapper}>{state && <DirectionalDrillingMonitor widgetState={state} />}</div>
      ) : (
        <NoWellChosen />
      )}
    </div>
  );
});
