import { goStorageAgent } from 'src/api/goStorageAgent';
import { throwApiError } from 'src/api/utils';
import { requireServiceAccessor } from 'src/packages/di';

import type { TWellListView, TWellsFilterView } from 'src/api-types/wells.types';
import type { TSerializedWorkspaceState, TWorkspaceStateResponse } from 'src/api-types/workspace.types';

export class WorkspaceApi {
  constructor(readonly auth = requireServiceAccessor('authService')) {}

  async createWorkspace(): Promise<void> {
    try {
      const response = await goStorageAgent.post('object/new/GOdashboard_Workbench', {
        ownerUserId: this.auth().userInfo.sub,
        tabs: [],
      });

      return response.data;
    } catch (error) {
      throwApiError(error);
    }
  }

  async fetchWellsFilterView(): Promise<TWellsFilterView> {
    try {
      const view = await goStorageAgent.get<TWellsFilterView>('view/GOdashboard_WellFilter');

      return view.data;
    } catch (error) {
      throwApiError(error);
    }
  }

  async fetchWellListView(): Promise<TWellListView> {
    try {
      const view = await goStorageAgent.get<TWellListView>('view/GOdashboard_WellDirectory');

      return view.data;
    } catch (error) {
      throwApiError(error);
    }
  }

  async fetchTabs(): Promise<TWorkspaceStateResponse> {
    try {
      const { data } = await goStorageAgent.get<TWorkspaceStateResponse>('object/list/GOdashboard_Workbench', {
        params: {
          ownerUserId: this.auth().userInfo.sub,
        },
      });

      return data;
    } catch (error) {
      throwApiError(error);
    }
  }

  async saveWorkspaceState(
    state: TSerializedWorkspaceState,
    workbenchId: number,
    currentTabId?: string
  ): Promise<void> {
    try {
      await goStorageAgent.patch(`object/GOdashboard_Workbench/${workbenchId}`, {
        ownerUserId: this.auth().userInfo.sub,
        tabs: state.tabs,
        currentTabId,
      });
    } catch (error) {
      throwApiError(error);
    }
  }
}
