import { action, makeObservable, observable } from 'mobx';

import { BaseWidgetEntityWithWellId } from 'src/entities/widget/BaseWidgetEntityWithWellId';
import { requireService } from 'src/packages/di';

import type { ImageBroadcastingWidgetState } from '../broadcast/ImageBroadcastingWidgetState';
import type { WidgetStateParams } from '../broadcast/types';
import type { SizeBoundaries, TWidgetOptions } from 'src/entities/widget/WidgetEntity';

interface WellLogsWidgetOptions {
  type: string;
  stateParams: Partial<WidgetStateParams>;
  wellId: number | null;
}

export type TWellBroadcastWidgetSerializedInternalState = {
  stateParams: Partial<WidgetStateParams>;
  wellId: number | null;
};

export class ImageBroadcastWidgetEntity extends BaseWidgetEntityWithWellId {
  readonly sizeBoundaries: SizeBoundaries = {
    minInPixels: {
      w: 150,
      h: 150,
    },
    maxInPixels: null,
    min: null,
    max: {
      w: 256,
      h: 100,
    },
  };

  @observable state: ImageBroadcastingWidgetState | null = null;
  @observable type: string;
  @observable wellId: number | null;
  @observable stateParams: Partial<WidgetStateParams>;
  @observable centerTitle?: string;
  @observable wellboreName: string | null = null;

  constructor(
    { type, stateParams, wellId }: WellLogsWidgetOptions,
    widgetOptions: TWidgetOptions,
    private readonly i18 = requireService('i18')
  ) {
    super(widgetOptions);

    this.type = type;
    this.wellId = wellId;

    this.stateParams = stateParams;

    makeObservable(this);
  }

  getNameT(): string {
    return 'widgets:ImageBroadcast.title';
  }

  @action.bound
  setWellId(wellId: number | null): void {
    this.wellId = wellId;
  }

  @action.bound
  setWellboreName(name: string): void {
    this.wellboreName = name;
  }

  @action.bound
  setStateParams(stateParams: Partial<WidgetStateParams>): void {
    this.stateParams = stateParams;
  }

  get isControlWidget(): boolean {
    return false;
  }

  get title(): string {
    return this.i18.t(this.getNameT());
  }

  @action.bound
  setState(state: ImageBroadcastingWidgetState | null): void {
    this.state = state;
  }

  @action.bound
  setFullscreen(value: boolean): void {
    this.isFullscreen = value;
    this.state?.setAppParams({
      fullScreen: value,
    });
  }

  @action.bound
  setCenterTitle(value: string): void {
    this.centerTitle = value;
  }
}
