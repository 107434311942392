import { VideoBroadcastWidgetState } from '@go-widgets/well-logs-widget';
import { action, comparer, makeObservable, observable, reaction } from 'mobx';

import type { VideobroadcastWidgetEntity } from './VideobroadcastWidget.entity';
import type { TCreateWidgetOptions, TGroup } from '../../../../entities/tab/TabEntity';
import type { WidgetEntity } from '../../../../entities/widget/WidgetEntity';
import type { GroupSelectStore } from '../group-select/GroupSelect.store';
import type { VideoBroadcastInitParams, VideoBroadcastStateParams } from '@go-widgets/well-logs-widget';
import type { IReactionDisposer, ObservableMap } from 'mobx';

import { WITH_WELLBORE_SORTING } from '../../../../api/consts';
import { lagacyAgent } from '../../../../api/legacyAgent';
import { wellLogsWidgetAgent } from '../../../../api/wellLogsWidgetAgent';
import { requireService } from '../../../../packages/di';
import { getNewAndDeletedFields } from '../../../../packages/shared/utils/getNewAndDeletedElements';
import { setWellIdInGroup } from '../../utils/setWellIdInGroup';
import { getCorrectLocale } from '../operational-parameters-widget/OperationalParametersWidget.utils';
import { WellListWidgetEntity } from '../well-list-widget/WellListWidget.entity';

export class VideobroadcastWidgetStore {
  private readonly videobroadcastEntity: VideobroadcastWidgetEntity;
  groupSelectStore: GroupSelectStore;
  private readonly initParams: VideoBroadcastInitParams; //todo
  @observable isLoading: boolean = false;
  @observable isReady: boolean = false;
  @observable hasError: boolean = false;
  @observable disposers: ObservableMap<WidgetEntity, IReactionDisposer> = observable.map();
  @observable isGroupSelectOpen: boolean = false;

  onCreateVideobroadcastWidget: (
    wellId: number | null,
    stateParams: Partial<VideoBroadcastStateParams> | null,
    options?: TCreateWidgetOptions
  ) => void;

  constructor(
    videobroadcastEntity: VideobroadcastWidgetEntity,
    groupSelectStore: GroupSelectStore,
    onCreateVideobroadcastWidget: (
      wellId: number | null,
      stateParams: Partial<VideoBroadcastStateParams> | null,
      options?: TCreateWidgetOptions
    ) => void,
    private readonly theme = requireService('theme'),
    private readonly otherSettings = requireService('otherSettings'),
    private readonly stompCachePublisherService = requireService('stompCachePublisherService'),
    private readonly notifications = requireService('notifications'),
    private readonly language = requireService('language'),
    private readonly wellLogsTemplateService = requireService('wellLogsTemplateService')
  ) {
    this.onCreateVideobroadcastWidget = onCreateVideobroadcastWidget;
    const { wellIndexType } = videobroadcastEntity;
    const wellLogsTemplatesManager = this.wellLogsTemplateService.getTemplatesManager(wellIndexType);
    this.videobroadcastEntity = videobroadcastEntity;
    this.groupSelectStore = groupSelectStore;

    this.initParams = {
      index: wellIndexType,
      legacyAxiosInstance: lagacyAgent,
      stompPublisher: this.stompCachePublisherService.stompPublisher,
      axiosInstance: wellLogsWidgetAgent,
      withWellboreSorting: WITH_WELLBORE_SORTING,
      dashboardService: wellLogsTemplatesManager.api,
    };

    makeObservable(this);
  }
  @action.bound
  exitFullScreen() {
    this.videobroadcastEntity.setFullscreen(false);
  }

  @action.bound
  setIsGroupSelectOpen(isOpen: boolean): void {
    this.isGroupSelectOpen = isOpen;
  }

  @action.bound
  setGroupForWellLogs(group: TGroup | null): void {
    this.videobroadcastEntity.setGroup(group ? group.id : null);
  }

  @action.bound
  setWellId(well: number | null): void {
    this.videobroadcastEntity.setWellId(well);
  }

  @action.bound
  setGroupSelectStore(groupSelectStore: GroupSelectStore) {
    this.groupSelectStore = groupSelectStore;
  }

  @action.bound
  private setReady(value: boolean): void {
    this.isReady = value;
  }

  private mapNewWidgets(widgets: WidgetEntity[]): void {
    for (const widget of widgets) {
      if (!(widget instanceof WellListWidgetEntity) || this.disposers.has(widget)) {
        continue;
      }

      const disposeWellListWidget = reaction(
        () => widget.selectedWellID,
        (wellId) => {
          this.videobroadcastEntity.setWellId(wellId);
        }
      );

      this.disposers.set(widget, disposeWellListWidget);
    }
  }

  @action.bound
  private setInActiveTab(value: boolean) {
    this.videobroadcastEntity.setInActiveTab(value);
  }

  private mapDeletedWidgets(widgets: WidgetEntity[]): void {
    for (const widget of widgets) {
      if (this.disposers.has(widget)) {
        const disposer = this.disposers.get(widget);

        disposer?.();

        this.disposers.delete(widget);
      }
    }
  }

  private getNewState(wellId: number): VideoBroadcastWidgetState {
    return new VideoBroadcastWidgetState(
      this.initParams,
      {
        // TODO: remove hardcode
        wellId,
        width: 500,
        height: 500,
        locale: getCorrectLocale(this.language.language),
        theme: this.theme.theme,
        active: this.videobroadcastEntity.inActiveTab,
        isAltNames: this.otherSettings.isAltNames,
      },
      this.videobroadcastEntity.stateParams,
      {
        onInitializedSuccess: () => {
          this.setReady(true);
        },
        onInitializationFailed: (_, error) => {
          this.setReady(false);

          console.error(error);
          this.notifications.showErrorMessageT('errors:failedToInitWidget');
        },
        onInternalStateChanged: (state) => {
          this.videobroadcastEntity.setStateParams(state);
        },
        onWellboreChanged: (wellboreName) => {
          this.videobroadcastEntity.setWellboreName(wellboreName);
        },
      }
    );
  }

  initWidgetByWellId(wellId: number) {
    const newState = this.getNewState(wellId);
    this.videobroadcastEntity.setState(newState);
  }

  effect = () => {
    this.setInActiveTab(true);

    const disposeGroupId = reaction(
      () => this.videobroadcastEntity.groupId,
      (groupId) => {
        if (groupId === null) return;

        const widgets = this.groupSelectStore.groupManager.getWidgetsByGroupId(groupId);
        setWellIdInGroup(widgets, this.videobroadcastEntity);
      }
    );

    const dispose = reaction(
      () => this.groupSelectStore.groupManager.getWidgetsByGroupId(this.videobroadcastEntity.groupId).slice(),
      (widgets, prevState) => {
        const prevWidgets = prevState ?? [];

        const { newElements: newWidgets, deletedElements: deletedWidgets } = getNewAndDeletedFields<WidgetEntity>(
          widgets,
          prevWidgets
        );

        this.mapNewWidgets(newWidgets);
        this.mapDeletedWidgets(deletedWidgets);
      },
      { fireImmediately: true, equals: comparer.shallow }
    );

    const disposeWellId = reaction(
      () => this.videobroadcastEntity.wellId,
      (wellId, prevWellId) => {
        if (wellId && !this.videobroadcastEntity.state) {
          const newState = this.getNewState(wellId);

          this.videobroadcastEntity.setState(newState);
        } else if (wellId && this.videobroadcastEntity.state && typeof prevWellId !== 'undefined') {
          if (this.videobroadcastEntity.stateParams.selectedCameras) {
            this.videobroadcastEntity.state.setSelectedCams(this.videobroadcastEntity.stateParams.selectedCameras);
          }

          this.videobroadcastEntity.state.setAppParams({
            wellId,
          });
        } else if (!wellId && this.videobroadcastEntity.state) {
          this.videobroadcastEntity.state.destroy();
          this.videobroadcastEntity.setState(null);
        }
      },
      { fireImmediately: true }
    );

    const disposeStateParams = reaction(
      () => ({
        theme: this.theme.theme,
        language: this.language.language,
        isAltNames: this.otherSettings.isAltNames,
      }),
      ({ theme, language, isAltNames }) => {
        this.videobroadcastEntity.state?.setAppParams({
          theme,
          locale: getCorrectLocale(language),
          isAltNames,
        });
      },
      {
        fireImmediately: true,
      }
    );

    return () => {
      this.setInActiveTab(false);
      dispose();
      disposeGroupId();
      disposeWellId();
      disposeStateParams();
    };
  };
}
