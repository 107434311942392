import { WellLogsWidget as GoWellLogsWidget } from '@go-widgets/well-logs-widget';
import {
  EditLightBig as EditIcon,
  FullArrowLight as FullIcon,
  ExportLight as ExportIcon,
  ReduceLight as MinimizeIcon,
} from '@profgeosoft-ui/icons';
import { Button, ConfigContext, Tooltip } from '@profgeosoft-ui/react';
import { observer } from 'mobx-react-lite';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useService } from 'src/packages/di';
import { IS_PORTABLE_DEVICE } from 'src/packages/shared/consts/isPortableDevice';

import type { WellLogsWidgetEntity } from './WellLogsWidget.entity';
import type { TWidgetRendererOptions } from '../workspace/services/WidgetRendererService';
import type { WellIndexType, WidgetStateParams } from '@go-widgets/well-logs-widget';
import type { TCreateWidgetOptions } from 'src/entities/tab/TabEntity';

import { NoWellChosen } from '../no-well-chosen';
import { WidgetHeader } from '../widget-header/WidgetHeader';

import { WellLogsWidgetStore } from './WellLogsWidget.store';

import styles from './WellLogsWidget.module.scss';

type WrapperProps = {
  entity: WellLogsWidgetEntity;
  options: TWidgetRendererOptions;
  onCreateWellLogsWidget(
    wellIndexType: WellIndexType,
    wellId: number | null,
    stateParams: Partial<WidgetStateParams> | null,
    options?: TCreateWidgetOptions
  ): void;
};

type Props = {
  store: WellLogsWidgetStore;
  entity: WellLogsWidgetEntity;
  options: TWidgetRendererOptions;
};

export const WellLogsWidgetWrapper = observer(function WellLogsWidgetWrapper({
  entity,
  options,
  onCreateWellLogsWidget,
}: WrapperProps) {
  const [store, setStore] = useState<WellLogsWidgetStore | null>(null);

  const storeService = useService('widgetStoreService');

  useEffect(() => {
    if (store) return;

    const _store = storeService.getStore<WellLogsWidgetEntity, WellLogsWidgetStore>(entity);

    if (_store) {
      setStore(_store);
    } else {
      const newStore = new WellLogsWidgetStore(entity, options.groupSelectStore, onCreateWellLogsWidget);

      storeService.setStore<WellLogsWidgetEntity, WellLogsWidgetStore>(entity, newStore);

      setStore(newStore);
    }
  }, [setStore, storeService, entity, onCreateWellLogsWidget, options.groupSelectStore, store]);

  if (!store) return null;

  return <WellLogsWidget store={store} entity={entity} options={options} />;
});

export const WellLogsWidget = observer(function WellLogsWidget({ entity, options, store }: Props) {
  const globalConfig = useContext(ConfigContext);
  const [wrapperElement, setWrapperElement] = useState<HTMLDivElement | null>(null);

  const {
    setGroupForWellLogs,
    enableEditMode,
    handleExport,
    exitFullScreen,
    isReady,
    isExitFullscreenFetching,
    effect,
    progress,
  } = store;

  const { t } = useTranslation();

  const { wellId, isEditMode, isFullscreen, state, setFullscreen, wellboreName } = entity;

  useEffect(effect, [effect]);

  const directories = useService('directories');
  const wells = directories.getObject('GOdashboard_Well');
  const wellName = wells?.find((item) => item.id === wellId)?.data.name ?? wellId;

  return (
    <div className={styles.wellLogsWidgetWrapper} ref={(ref) => setWrapperElement(ref)}>
      <WidgetHeader
        entity={entity}
        onWidgetChangeGroup={setGroupForWellLogs}
        groupSelectStore={options.groupSelectStore}
        widgetName={entity.title}
        wellName={wellName}
        wellboreName={wellboreName}
        onWidgetDelete={() => {
          options.onWidgetDelete(entity);
        }}
        progress={progress}
        showCloseButton={!isFullscreen}
        draggable={!isFullscreen}
        renderAdditionalButtons={() => {
          if (!isReady) return null;

          return (
            <>
              {!isEditMode && !IS_PORTABLE_DEVICE && (
                <Tooltip title={t('common:Widget.edit')} delay={400}>
                  <Button
                    onClick={enableEditMode}
                    variant="flat"
                    icon={<EditIcon className={styles.icon} />}
                    className={styles.button}
                  />
                </Tooltip>
              )}
              <Tooltip title={t('common:Widget.export')} delay={400}>
                <Button
                  onClick={handleExport}
                  variant="flat"
                  icon={<ExportIcon className={styles.icon} />}
                  className={styles.button}
                />
              </Tooltip>
              {!isFullscreen && (
                <Tooltip title={t('common:Widget.fullscreen')} delay={400}>
                  <Button
                    onClick={() => setFullscreen(true)}
                    variant="flat"
                    icon={<FullIcon className={styles.icon} />}
                    className={styles.button}
                  />
                </Tooltip>
              )}
              {isFullscreen && (
                <Tooltip title={t('common:Widget.collapse')} delay={400}>
                  <Button
                    onClick={() => exitFullScreen(globalConfig)}
                    variant="flat"
                    icon={<MinimizeIcon className={styles.icon} />}
                    disabled={isExitFullscreenFetching}
                    className={styles.button}
                  />
                </Tooltip>
              )}
            </>
          );
        }}
      />
      {wrapperElement &&
        (state ? (
          <div className={styles.wrapper}>
            <GoWellLogsWidget widgetState={state} externalContainer={wrapperElement} />
          </div>
        ) : (
          <NoWellChosen />
        ))}
    </div>
  );
});
