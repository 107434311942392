import { Field, InputNumber, Switch, RadioGroup } from '@profgeosoft-ui/react';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { OthersEntity } from './Others.entity';
import type { Stack } from '../../../../packages/data/structures/Stack';
import type { BaseSettingsScreen } from '../screen/BaseSettingsScreen';

import { OthersScreenStore } from './OthersScreen.store';

import styles from './Others.module.scss';

type Props = {
  stack: Stack<BaseSettingsScreen>;
  screen: OthersEntity;
};

export const OthersScreen = observer(function OthersScreen({ screen, stack }: Props) {
  const [store] = useState(() => new OthersScreenStore(stack, screen));
  const { t } = useTranslation();

  return (
    <article className={styles.column}>
      <div className={styles.contentWrapper}>
        <div className={styles.settingsOption}>
          <Switch
            size="s"
            checked={store.isAltNames}
            onChange={(value: boolean) => {
              store.setIsAltNamesOn(value);
            }}
          />
          <span>{t('settings:Others:publicNames')}</span>
        </div>
        <div className={styles.settingsOption}>
          <Switch
            size="s"
            checked={store.isColoredParamsHeaders}
            onChange={(value: boolean) => {
              store.setIsColoredParamsHeaders(value);
            }}
          />
          <span>{t('settings:Others:coloredParametersHeaders')}</span>
        </div>
        <div className={styles.blockTitle} style={{ marginBottom: 8 }}>
          {t('settings:Others:widgetHeaderFontSize')}
        </div>
        <div style={{ display: 'flex' }}>
          <Field hint={t('settings:Others:widgetHeaderFontSizeHint')} className={styles.settingsField}>
            <InputNumber
              size="s"
              value={store.widgetHeaderFontSize}
              min={13}
              max={40}
              suffix="рх"
              onChange={(value) => {
                if (!Number.isNaN(value)) {
                  store.setWidgetHeaderFontSize(Number(value));
                }
              }}
            />
          </Field>
          <div style={{ marginLeft: 24 }}>
            <span style={{ color: 'var(--widget-header-title)' }}>{t('settings:Others:example')} </span>
            <span style={{ fontSize: Math.min(40, store.widgetHeaderFontSize) }}>
              VT_535, Main
              <span style={{ color: 'var(--widget-header-title)' }}>
                : {`${t('widgets:WellLogs.title')} (${t('widgets:time')})`}
              </span>
            </span>
          </div>
        </div>
        <div className={styles.blockTitle}>{t('settings:Others:applicationPerformance')}</div>
        <div className={styles.blockHint}>{t('settings:Others:applicationPerformanceHint')}</div>
        <div style={{ marginTop: 16 }}>
          <RadioGroup
            style={{ display: 'flex', gap: 56 }}
            value={store.graphicsPerformance}
            items={[
              { value: 'ultra', label: t('settings:Others:ultra') },
              { value: 'high', label: t('settings:Others:high') },
              { value: 'medium', label: t('settings:Others:medium') },
              { value: 'low', label: t('settings:Others:low') },
            ]}
            onChange={store.setGraphicsPerformance}
          />
        </div>
      </div>
    </article>
  );
});
