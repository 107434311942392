import { action, makeObservable, observable } from 'mobx';

import { storage } from '../shared/consts/storage';

export type GraphicsPerformance = 'ultra' | 'high' | 'medium' | 'low';
export interface IOtherSettingsService {
  isAltNames: boolean;
  isColoredParamsHeaders: boolean;
  widgetHeaderFontSize: number;
  graphicsPerformance: GraphicsPerformance;
  selectAltNames(value: boolean): void;
  selectColoredParamsHeaders(value: boolean): void;
  selectWidgetHeaderFontSize(value: number): void;
  selectGraphicsPerformance(value: GraphicsPerformance): void;
  graphicsPerformanceFps: number;
}

export class OtherSettingsStore implements IOtherSettingsService {
  @observable isAltNames: boolean;
  @observable isColoredParamsHeaders: boolean;
  @observable widgetHeaderFontSize: number;
  @observable graphicsPerformance: GraphicsPerformance;

  constructor() {
    const storedAltNames = storage.GET<boolean>('altNames');
    const storedParamsHeaders = storage.GET<boolean>('coloredParamsHeaders');
    this.isAltNames = storedAltNames != null ? storedAltNames : false;
    this.isColoredParamsHeaders = storedParamsHeaders != null ? storedParamsHeaders : false;
    this.widgetHeaderFontSize = storage.GET<number>('widgetHeaderFontSize') || 13;
    this.graphicsPerformance = storage.GET<GraphicsPerformance>('graphicsPerformance') || 'medium';
    makeObservable(this);
  }

  private _setAltNames(value: boolean) {
    this.isAltNames = value;
    storage.SET('altNames', value);
  }

  private _setParamsHeaders(value: boolean) {
    this.isColoredParamsHeaders = value;
    storage.SET('coloredParamsHeaders', value);
  }

  private _setWidgetHeaderFontSize(value: number) {
    this.widgetHeaderFontSize = value;
    storage.SET('widgetHeaderFontSize', value);
  }

  private _setGraphicsPerformance(value: GraphicsPerformance) {
    this.graphicsPerformance = value;
    storage.SET('graphicsPerformance', value);
  }

  @action.bound
  selectAltNames(value: boolean) {
    this._setAltNames(value);
  }

  @action.bound
  selectColoredParamsHeaders(value: boolean) {
    this._setParamsHeaders(value);
  }

  @action.bound
  selectWidgetHeaderFontSize(value: number) {
    this._setWidgetHeaderFontSize(value);
  }

  @action.bound
  selectGraphicsPerformance(value: GraphicsPerformance) {
    this._setGraphicsPerformance(value);
  }

  get graphicsPerformanceFps() {
    if (this.graphicsPerformance === 'ultra') {
      return 60;
    }
    if (this.graphicsPerformance === 'medium') {
      return 20;
    }
    if (this.graphicsPerformance === 'low') {
      return 10;
    }
    return 30;
  }
}
