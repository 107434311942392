import { action, makeObservable, observable, reaction } from 'mobx';

import { BaseWidgetEntityWithWellId } from 'src/entities/widget/BaseWidgetEntityWithWellId';
import { requireService } from 'src/packages/di';

import type { WellIndexType, ParametersStateParams, ParametersPanelWidgetState } from '@go-widgets/well-logs-widget';
import type { IReactionDisposer } from 'mobx';
import type { SizeBoundaries, TWidgetOptions } from 'src/entities/widget/WidgetEntity';

interface OperationalParametersOptions {
  type: string;
  stateParams: Partial<ParametersStateParams>;
  wellId: number | null;
  wellIndexType: WellIndexType;
  enableSound: boolean;
}

export type TOperationalParametersSerializedInternalState = {
  stateParams: Partial<ParametersStateParams>;
  wellId: number | null;
  wellIndexType: WellIndexType;
  enableSound: boolean;
};

export class OperationalParametersWidgetEntity extends BaseWidgetEntityWithWellId {
  readonly sizeBoundaries: SizeBoundaries = {
    minInPixels: {
      w: 150,
      h: 150,
    },
    maxInPixels: null,
    min: null,
    max: {
      w: 256,
      h: 100,
    },
  };

  @observable state: ParametersPanelWidgetState | null = null;
  @observable type: string;
  @observable wellId: number | null;
  @observable stateParams: Partial<ParametersStateParams>;
  @observable wellIndexType: WellIndexType;
  @observable inActiveTab: boolean = false;
  @observable enableSound: boolean;
  @observable hasAlarm: boolean = false;
  @observable disposer: IReactionDisposer;
  @observable wellboreName: string | null = null;

  constructor(
    { type, stateParams, wellId, wellIndexType, enableSound }: OperationalParametersOptions,
    widgetOptions: TWidgetOptions,
    private readonly i18 = requireService('i18'),
    private readonly soundAlert = requireService('soundAlert')
  ) {
    super(widgetOptions);

    this.type = type;
    this.wellId = wellId;
    this.stateParams = stateParams;
    this.wellIndexType = wellIndexType;
    this.enableSound = enableSound;

    this.disposer = reaction(
      () => this.enableSound && this.hasAlarm,
      (initSound) => {
        if (initSound) {
          this.soundAlert.addNewAlert();
        } else {
          this.soundAlert.removeAlert();
        }
      }
    );

    makeObservable(this);
  }

  getNameT(): string {
    return 'widgets:OperationalParams.title';
  }

  @action.bound
  setWellId(wellId: number | null): void {
    this.wellId = wellId;
  }

  @action.bound
  setWellboreName(name: string): void {
    this.wellboreName = name;
  }

  @action.bound
  setStateParams(stateParams: Partial<ParametersStateParams>): void {
    this.stateParams = stateParams;
  }

  get isControlWidget(): boolean {
    return false;
  }

  get title(): string {
    const index = this.i18.t(this.wellIndexType === 'LOG_DEPTH' ? 'widgets:depth' : 'widgets:time');
    return `${this.i18.t(this.getNameT())} (${index})`;
  }

  @action.bound
  setState(state: ParametersPanelWidgetState | null): void {
    this.state = state;
  }

  @action.bound
  setFullscreen(value: boolean): void {
    this.isFullscreen = value;
  }

  @action.bound
  setInActiveTab(value: boolean): void {
    this.inActiveTab = value;
    this.state?.setAppParams({
      active: this.inActiveTab,
    });
  }

  override destroy() {
    this.state?.destroy();
    this.disposer();
  }

  @action.bound
  toggleWidgetSound(): void {
    this.enableSound = !this.enableSound;
  }

  @action.bound
  setHasAlarm(state: boolean): void {
    this.hasAlarm = state;
  }
}
